import React from 'react'
import Layout from '../layouts/index'
import Helmet from 'react-helmet'

export default class Privacy extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet title={'Security | Suggested - Build better products'} />
        <div className="page legal-page">
          <h1>Security</h1>

          <p>
            <b>Last updated: 01 February 2019</b>
          </p>

          <p>
            At Suggested we take the security and integrity of your data very
            seriously. We have put controls in place, to make sure your data is
            safe, covering the technical aspects of providing our service, all
            the way up to our team.
          </p>

          <h4>Product security</h4>
          <p>
            <b>Email/password login</b> - We enforce a minimum of 8 characters
            for passwords, and they are always stored encrypted at rest (see
            below).
          </p>
          <p>
            <b>Social login</b> - We provide integrations for logging in with
            Google, Facebook and Github using the OAuth2 mechanism.
          </p>
          <p>
            <b>Passwords</b> - If using a password to login, we use PBKDF to
            store credentials at rest. Passwords are never stored in clear text.{' '}
          </p>

          <h4>Network and application security</h4>
          <p>
            <b>Data hosting and storage</b> - Our servers are hosted with
            DigitalOcean. Access to these servers is restricted to Suggested's
            team members who need it to perform their job. More information
            about DigitalOcean's security policy{' '}
            <a href="https://www.digitalocean.com/legal/data-security/">
              can be found here.
            </a>
          </p>
          <p>
            <b>Website</b> - All data transmitted from your browser to
            Suggested's servers is encrypted with SSL.
          </p>
          <p>
            <b>APIs</b> - Our application and API endpoints are encrypted with
            SSL/TLS.
          </p>
          <p>
            <b>Incident response</b> - We have an internal process to handle
            security events.
          </p>
          <p>
            <b>Backups</b> - Suggested uses a SQL database to manage customer
            data. Data is backed up daily to a separate data center to ensure
            safety.
          </p>
          <p>
            <b>Permissions</b> - Your data is only accessible to key Suggested
            team members who require it for their job. We only access your
            private data in case of an issue, where it is absolutely necessary
            and as a last resort.
          </p>

          <h4>Additional security</h4>
          <p>
            <b>PCI</b> - All payments go through our payment processor - Stripe.
            Details about their security setup and PCI compliance can be found
            at Stripe's{' '}
            <a href="https://stripe.com/docs/security">security page.</a>
          </p>
        </div>
      </Layout>
    )
  }
}
